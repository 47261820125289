<nz-row class="clientes">
    <nz-row>
        <div class="btn-download">
            <a style="cursor: pointer" (click)="exportexcel()">
                <i nz-icon nzType="download" nzTheme="outline"></i> Descargar Reporte
            </a>
            <h2 class="report-title"> Ventas por eCommerce </h2>
        </div>
    </nz-row>
    <nz-row>
      <nz-col nzSpan="4" nzOffset="6">
          <a class="control-filter" (click)="verFiltroFecha()">
              Filtrar por Fecha
          </a>
      </nz-col>
      <nz-col nzSpan="4" nzOffset="1">
          <div [nzDropdownMenu]="menu"
          nz-dropdown
          #dropdown="nzDropdown"
          [class.ant-table-filter-open]="dropdown.nzVisible"
          nzTrigger="click"
          nzPlacement="bottomRight"
          [nzClickHide]="false"
          nzTableFilter class="control-filter">
              Buscar por transacción
          </div>
      </nz-col>
      <nz-col nzSpan="4" nzOffset="1">
          <a class="control-filter">
            Buscar por Cliente
          </a>
      </nz-col>
    </nz-row>
    <!--Espacios de Filtros -->
    <nz-row class="block-filter" *ngIf="filtroFecha">
      <h4 class="filter-title">Filtrar por Fecha</h4>
      <nz-col nzSpan="6" nzOffset="4">
        <span class="filter-lbl">Fecha Inicial :</span>
        <nz-date-picker name="fechaInicial" nzDisabledTime [(ngModel)]="fechaInicial" (ngModelChange)="setFechaInicial()"></nz-date-picker>
      </nz-col>
      <nz-col nzSpan="6" nzOffset="1">
        <span class="filter-lbl">Fecha Final :</span>
        <nz-date-picker name="fechaFinal" nzDisabledTime [nzAllowClear]="false"	 [(ngModel)]="fechaFinal" (ngModelChange)="setFechaFinal()"></nz-date-picker>
      </nz-col>
      <nz-col nzSpan="4" nzOffset="1">
        <a class="btn-filter">Filtrar</a>
      </nz-col>
    </nz-row>

    <!-- FILTRAR POR CI -->

    <nz-row class="block-filter" *ngIf="filtroCI">
      <h4 class="filter-title">Filtrar por Nro. orden</h4>
      <nz-col nzSpan="6" nzOffset="6">
        <span class="filter-lbl">Nro. Orden :</span>
        <input nz-input placeholder="0000" [(ngModel)]="valueCI" />
      </nz-col>
      <nz-col nzSpan="4" nzOffset="1">
        <a class="btn-filter">Filtrar</a>
      </nz-col>
    </nz-row>
    
    <nz-row style="overflow-x:auto;">
      <nz-table #nestedTable [nzData]="mostrarTransacciones" [nzPageSize]="100" nzTableLayout="fixed" id="excel-table" class="tabla-listado-transacciones">
        <thead>
            <tr>
                <th nzShowExpand></th>
                <th nzShowSort [(nzSort)]="sortValue" (nzSortChange)="search(searchName)">Nro. Orden</th>
                <th>Nro. Transaccion</th>
                <th>Nro. Proforma</th>
                <th>Fecha</th>
                <th>Nombre Completo</th>
                <th>Telefono</th>
                <th>Total Compra</th>
                <th>Metodo Pago</th>
                <th nzShowFilter [nzFilters]="listOfFilterEstado" (nzFilterChange)="filterAddressChange($event)">Estado Transacción</th>
                <th>Estado Delivery</th>
                <th>Editar Estado Transacción</th>
                <th>Editar Estado Delivery</th>
            </tr>
        </thead>
        <tbody>
          <ng-template ngFor let-data let-index="index" [ngForOf]="nestedTable.data" >
            <tr>
              <td nzShowExpand [(nzExpand)]="data.expand" (click)="obtenerProductos(index)"></td>
              <td>{{ data.id_compra }}</td>
              <td>{{ data.nro_transaccion }}</td>
              <td>{{ data.nro_proforma }}</td>
              <!--Se cambio aqui-->
              <td>{{ data.fecha_compra }}</td>
              <td>{{ data.nombre }} {{ data.apellidos }}</td>
              <td>{{ data.telefono }}</td>
              <!--<td *ngIf="!data['facturacion']">No tiene telefono</td>-->
              <td>{{ data.total_compra }}</td>
              <td>{{ data.metodo_pago }}</td>
              <td>
                  <nz-badge *ngIf="data.estado === 'Pendiente'" [nzStatus]="'warning'" [nzText]="data.estado"></nz-badge>
                  <nz-badge *ngIf="data.estado === 'Pagado'" [nzStatus]="'processing'" [nzText]="data.estado"></nz-badge>
              </td>
              <td>
                  <nz-badge *ngIf="data.estado_delivery === 'Sin delivery'" nzStatus="default" [nzText]="data.estado_delivery"></nz-badge>
                  <nz-badge *ngIf="data.estado_delivery === 'En preparacion'" [nzStatus]="'warning'" [nzText]="data.estado_delivery"></nz-badge>
                  <nz-badge *ngIf="data.estado_delivery === 'En camino'" [nzStatus]="'error'" [nzText]="data.estado_delivery"></nz-badge>
                  <nz-badge *ngIf="data.estado_delivery === 'Entregado'" nzColor="#D36135" [nzText]="data.estado_delivery"></nz-badge>
                  <nz-badge *ngIf="data.estado_delivery === 'Cancelado'" [nzStatus]="'Default'" [nzText]="data.estado_delivery"></nz-badge>
              </td>
              <td class="view_details">
                <div *ngIf="!(data.estado === 'Pagado') && !(data.metodo_pago ==='QR Simple')">
                    <button nz-button nzType="default" (click)="estadoTransaccion($event,1, data.nro_transaccion)" class="btn-delivery btn-pagado"> Pagado </button>
                    <button nz-button nzType="dashed" (click)="estadoTransaccion($event,3, data.nro_transaccion)" class="btn-delivery btn-cancelar">Cancelado</button>
                  <!--<nz-select [(ngModel)]="selectEstadoTransaccion"  nzPlaceHolder="Cambiar Estado" (ngModelChange)="estadoTransaccion($event,selectEstadoTransaccion, data.nro_transaccion)" class="editar-transac">
                    <nz-option nzValue="1" nzLabel="Pagado"></nz-option>
                    <nz-option nzValue="3" nzLabel="Cancelado"></nz-option>
                  </nz-select>-->
                </div>
                <div *ngIf="(data.estado === 'Pagado')">
                  <i nz-icon nzType="check" nzTheme="outline" class="white-pers"></i>
                </div>
              </td>
              <td class="view_delivery">
                <div *ngIf="!(data.estado_delivery === 'En Preparación')">
                    <button nz-button nzType="primary" (click)="estadoDelivery($event,3, data.nro_transaccion)" class="btn-delivery btn-camino">En Camino</button>
                    <button nz-button nzType="default" (click)="estadoDelivery($event,4, data.nro_transaccion)" class="btn-delivery btn-entregado">Entregado</button>
                    <button nz-button nzType="dashed" (click)="estadoDelivery($event,5, data.nro_transaccion)" class="btn-delivery btn-cancelado">Cancelado</button>
                  <!--<nz-select [(ngModel)]="selectEstadoDelivery"  nzPlaceHolder="Cambiar Estado" (ngModelChange)="estadoDelivery($event,selectEstadoDelivery, data.nro_transaccion)" class="editar-transac">
                    <nz-option nzValue="3" nzLabel="En Camino"></nz-option>
                    <nz-option nzValue="4" nzLabel="Entregado"></nz-option>
                    <nz-option nzValue="5" nzLabel="Cancelado"></nz-option>
                  </nz-select>-->
                </div>
                
                <div *ngIf="(data.estado_delivery === 'Entregado')">
                  Entregado
                </div>
              </td>
            </tr>
            <tr [nzExpand]="data.expand">
              <td colspan="11">
                  <nz-row>
                    <h3 class="title-detail">Datos de facturación</h3>
                  </nz-row>
                  <nz-row *ngIf="mostrarTransacciones[index]['facturacion']">
                      <nz-col [nzSpan]="23" [nzOffset]="1">
                          <nz-row>
                              <nz-col [nzSpan]="4" class="title-address">NIT</nz-col>
                              <nz-col [nzSpan]="4" class="title-address">Nombres</nz-col>
                              <nz-col [nzSpan]="4" class="title-address">Apellidos</nz-col>
                              <nz-col [nzSpan]="4" class="title-address">Teléfono</nz-col>
                          </nz-row>
                          <nz-row>
                              <nz-col [nzSpan]="4">{{ mostrarTransacciones[index]['facturacion'].nit }}</nz-col>
                              <nz-col [nzSpan]="4">{{ mostrarTransacciones[index]['facturacion'].nombre }}</nz-col>
                              <nz-col [nzSpan]="4">{{ mostrarTransacciones[index]['facturacion'].apellido }}</nz-col>
                              <nz-col [nzSpan]="4">{{ mostrarTransacciones[index]['facturacion'].telefono }}</nz-col>
                          </nz-row>
                      </nz-col>
                  </nz-row>

                  <nz-row *ngIf="!mostrarTransacciones[index]['facturacion']">
                      <nz-col [nzSpan]="23" [nzOffset]="1">
                          NO TIENE DATOS DE FACTURACIÓN, POR FAVOR COMUNICARSE CON EL CLIENTE 
                      </nz-col>
                  </nz-row>
              </td>
            </tr>
            
            <tr [nzExpand]="data.expand" *ngIf="mostrarTransacciones[index]['direccion']">
              <td colspan="11">
                <nz-row>
                  <h3 class="title-detail">Dirección de envío</h3>
                </nz-row>
                <nz-row>
                    <nz-col [nzSpan]="23" [nzOffset]="1"> 
                        <nz-row>
                            <nz-col [nzSpan]="4" class="title-address">Zona/Barrio</nz-col>
                            <nz-col [nzSpan]="4" class="title-address">Calle</nz-col>
                            <nz-col [nzSpan]="4" class="title-address">Nro. Edificio Casa</nz-col>
                            <nz-col [nzSpan]="4" class="title-address">Referencia</nz-col>
                            <nz-col [nzSpan]="4" class="title-address">Link mapa</nz-col>
                        </nz-row>
                        <nz-row>
                            <nz-col [nzSpan]="4">Z/ {{ mostrarTransacciones[index]['direccion'].zona }}, Barrio  {{ mostrarTransacciones[index]['direccion'].barrio }}</nz-col>
                            <nz-col [nzSpan]="4">{{ mostrarTransacciones[index]['direccion'].calle }}</nz-col>
                            <nz-col [nzSpan]="4">{{ mostrarTransacciones[index]['direccion'].nro_edificio_casa }}</nz-col>
                            <nz-col [nzSpan]="4">{{ mostrarTransacciones[index]['direccion'].detalle_referencia }}</nz-col>
                            <nz-col [nzSpan]="4"><a href="https://maps.google.com/?q={{ mostrarTransacciones[index]['direccion'].latitud }},{{ mostrarTransacciones[index]['direccion'].longitud }}" target="_blanck">Ver dirección en Mapa</a></nz-col>
                        </nz-row>
                    </nz-col>
                </nz-row>
              </td>
            </tr>
            <tr [nzExpand]="data.expand">
              <td colspan="11"><h3 class="title-detail">Detalle de Transacción</h3></td>
            </tr>
            <tr [nzExpand]="data.expand" class="ultima-celda">
              <td></td>
              <td colspan="10">
                
                <nz-table #innerTable [nzData]="mostrarTransacciones[index]['productos']" nzSize="middle" [nzShowPagination]="false">
                  <thead>
                    <tr>
                      <th>Código</th>
                      <th>Repuesto</th>
                      <th>Precio Unitario</th>
                      <th>Cantidad</th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of innerTable.data">
                      <td>{{ data.numero_parte }}</td>
                      <td>{{ data.nombre_repuesto }}</td>
                      <td>{{ data.precio }} Bs.</td>
                      <td>{{ data.cantidad }}</td>
                      <td>{{ data.subtotal }} Bs.</td>
                    </tr>
                  </tbody>
                </nz-table>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </nz-table>

      <nz-dropdown-menu #menu="nzDropdownMenu">
        <div class="search-box">
          <input type="text" nz-input placeholder="Buscar por Transacción" [(ngModel)]="searchValue" />
          <button nz-button nzSize="small" nzType="primary" (click)="searchTransaccion()" class="search-button">
            Buscar
          </button>
          <button nz-button nzSize="small" (click)="reset()">Reset</button>
        </div>
      </nz-dropdown-menu>
    </nz-row>
</nz-row>