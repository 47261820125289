import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx'; 
import { CitasService } from 'src/app/services/citas.service';
import { ActivatedRoute, Router } from '@angular/router';
import { format } from 'date-fns';
import { formatWithOptions } from 'date-fns/fp';
import { es } from 'date-fns/locale';
import { NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-citas-programadas',
  templateUrl: './programadas.component.html',
  styleUrls: ['./programadas.component.css']
})
export class ProgramadasComponent implements OnInit {
  dateToString = formatWithOptions({ locale: es }, 'd MMMM');
  mapOfExpandData: { [key: string]: boolean } = {};
  citasDatos ;
  citasDatosChaperio ;

  /*name of the excel-file which will be downloaded. */ 
  reporteEncuestas= 'Clientes-Kaizen.xlsx'; 
  today = new Date();   
  fechaInicial;
  fechaFinal;
  horas = [];
  horaSelected;
  filtroFecha;
  filtroCI;
  valueCI: string;

  //Filtros
  listOfFilterGenero = [{ text: 'Femenino', value: 'F' }, { text: 'Marculino', value: 'M' }];
  sortClientID: string | null = null;
  sortValue: string | null = null;
  searchValue = '';
  listOfSearchApellidos: string[] = [];
  listOfDisplayData;

  listOfDisplayDataChaperio;

  citaPrint;
  
  cantidadCitas=0;
  cantidadCitasChaperio=0;

  isVisibleConfirmar = false;
  loading = false;
  
  constructor (private citasService: CitasService, private router: Router, private modalService: NzModalService) {
      this.filtroFecha = false;
      this.filtroCI = false;
      
  }

  verFiltroFecha() {
    this.filtroFecha = true;
    this.filtroCI = false;
  }

  verFiltroCI() {
    this.filtroFecha = false;
    this.filtroCI = true;
  }

  ngOnInit(): void {
    this.fechaInicial = format(new Date(), 'yyyy-MM-dd');
    this.fechaFinal = format(new Date(), 'yyyy-MM-dd');
      this.mostrarRepuestos(this.fechaFinal,this.fechaFinal);
  }

  mostrarRepuestos(start:any, end:any) {
    this.citasDatos=[];
    this.listOfDisplayData=this.citasDatos;
    this.loading = true;
    this.citasService.obtenerCitas(start,end).subscribe( data => {
      this.citasDatos = data['citas'];
      this.listOfDisplayData = this.citasDatos;
      this.citaPrint = this.listOfDisplayData[0];
      this.cantidadCitas = this.citasDatos.length;

      this.mostrarRepuestosChaperio(start,end);
      this.loading = false;
      console.log(this.citasDatos);
    }, error => {
      this.cantidadCitas = 0;
      this.citasDatos = [];
      this.listOfDisplayData = this.citasDatos;
      console.log(error); // Imprimimos en consola el error
    });
  }

  mostrarRepuestosChaperio(start:any, end:any) {
    this.citasDatosChaperio=[];
    this.listOfDisplayDataChaperio=this.citasDatosChaperio;
    this.citasService.obtenerCitasChaperio(start,end).subscribe( data => {
      this.citasDatosChaperio = data['citas'];
      this.listOfDisplayDataChaperio = this.citasDatosChaperio;
      this.citaPrint = this.listOfDisplayDataChaperio[0];
      this.cantidadCitasChaperio = this.citasDatosChaperio.length;
      console.log(this.citasDatosChaperio);
    }, error => {
      this.cantidadCitasChaperio = 0;
      this.citasDatosChaperio = [];
      this.listOfDisplayDataChaperio = this.citasDatosChaperio;
      console.log(error); // Imprimimos en consola el error
    });
  }

  exportexcel(): void 
  {
     /* table id is passed over here */   
     let element = document.getElementById('excel-table'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Kaizen-Motors_Citas.xlsx');
    
    
  }

  reset(): void {
    this.searchValue = '';
    this.search();
  }

  sort(sortName: string, value: string): void {
    this.sortClientID = sortName;
    this.sortValue = value;
    this.search();
  }

  filterAddressChange(value: string[]): void {
    this.listOfSearchApellidos = value;
    this.search();
  }

  search(): void {
      
    const filterFunc = (item: { apellidos: string; ciudad: string; direccion: string; fecha_nacimiento: string; genero: string; id_persona: Number; latitud: string; longitud: string; nom_tit: string; nombre: string; nro_documento: string }) => {
      return (
        (this.listOfSearchApellidos.length
          ? this.listOfSearchApellidos.some(address => item.genero.indexOf(address) !== -1)
          : true) && item.nro_documento.indexOf(this.searchValue) !== -1
      );
    };
    const data = this.citasDatos.filter((item: { apellidos: string; ciudad: string; direccion: string; fecha_nacimiento: string; genero: string; id_persona: Number; latitud: string; longitud: string; nom_tit: string; nombre: string; nro_documento: string }) => filterFunc(item));
    this.listOfDisplayData = data.sort((a, b) =>
      this.sortValue === 'ascend'
        ? a[this.sortClientID!] > b[this.sortClientID!]
          ? 1
          : -1
        : b[this.sortClientID!] > a[this.sortClientID!]
        ? 1
        : -1
    );
  }

    printDiv(divName, indexPrint) {
        this.citaPrint = this.listOfDisplayData[indexPrint];
        console.log(this.citaPrint);

        setTimeout(() => {
          var printContents = document.getElementById(divName).innerHTML;
          var originalContents = document.body.innerHTML;
          document.body.innerHTML = printContents;
          window.print();
          document.body.innerHTML = originalContents;
  
        }, 1000);

        window.onafterprint = function(){
          console.log("Printing completed...");
        }

        const that = this;
        const beforePrint = function() {
            console.log('Functionality to run before printing.');
        };
    
        const afterPrint = function() {
            console.log('Functionality to run after printing');
            window.location.reload();
        };
        
        if (window.matchMedia) {
            var mediaQueryList = window.matchMedia('print');
            mediaQueryList.addListener(function(mql) {
                if (mql.matches) {
                  beforePrint();
                } else {
                    afterPrint();
                }
            });
        }
        window.onbeforeprint = beforePrint;
        window.onafterprint = afterPrint;
    }

    setFecha(fechaI, fechaF) {
      console.log(fechaI);
      console.log(fechaF);
        this.mostrarRepuestos(format(fechaI, 'yyyy-MM-dd'),format(fechaF, 'yyyy-MM-dd'));   
    }

    setFechaF(fecha) {
      this.mostrarRepuestos(format(this.fechaInicial, 'yyyy-MM-dd'),format(fecha, 'yyyy-MM-dd'));
    }

    editarAsistencia(idCita, placaVehiculo, precio) {
      this.modalService.confirm({
        nzTitle: 'Asistió a su cita?',
        nzContent: 'Placa: '+placaVehiculo,
        nzOkText: 'Si',
        nzCancelText: 'No',
        nzOnOk: () => {
          this.loading = true;
          const datos = {
            id_cita: idCita,
            confirmacion_asistencia: 1,
            _method: 'PUT'
          };
          this.citasService.actualizar(datos).subscribe( data => {
            this.loading = false;
            alert("Asistencia confirmada!");
            this.mostrarRepuestos(this.fechaInicial,this.fechaFinal);
            this.isVisibleConfirmar = false;
          }, error => {
            alert("No se pudo confirmar asistencia!");
            console.log(error); // Imprimimos en consola el error
          });
        },
        nzOnCancel: () => {
          this.loading = true;
          const datos = {
            id_cita: idCita,
            confirmacion_asistencia: 0,
            _method: 'PUT'
          };
          this.citasService.actualizar(datos).subscribe( data => {
            alert("El cliente no asistió!");
            this.isVisibleConfirmar = false;
            this.mostrarRepuestos(this.fechaInicial,this.fechaFinal);
            this.loading = false;
          }, error => {
            alert("No se pudo confirmar asistencia!");
            console.log(error); // Imprimimos en consola el error
          });
        }
      });
    }
    showModalConfirmar(idCita, placaVehiculo): void {
      //this.isVisibleConfirmar = true;
      this.modalService.confirm({
        nzTitle: 'Confirmar cita en nuestro taller?',
        nzContent: 'Placa: '+placaVehiculo,
        nzOkText: 'Si',
        nzCancelText: 'No',
        nzOnOk: () => {
          //localStorage.clear();
          this.editarConfirmacion(idCita);
          //this.mostrarRepuestos(this.fechaInicial,this.fechaFinal);
        }
      });
    }
    editarConfirmacion(idCita) {
      this.loading = true;
      const datos = {
        id_cita: idCita,
        confirmacion_cita: 1,
        _method: 'PUT'
      };
      //this.mostrarRepuestos(format(this.fechaInicial, 'yyyy-MM-dd'),format(this.fechaFinal, 'yyyy-MM-dd'));
      this.citasService.actualizar(datos).subscribe( data => {
        alert("Cita confirmada!");
        this.isVisibleConfirmar = false;
        console.log(this.fechaInicial+"   "+this.fechaFinal);
        this.mostrarRepuestos(format(this.fechaInicial, 'yyyy-MM-dd'),format(this.fechaFinal, 'yyyy-MM-dd'));
        this.loading = false;
      }, error => {
        alert("No se pudo confirmar!");
        
        this.mostrarRepuestos(format(this.fechaInicial, 'yyyy-MM-dd'),format(this.fechaFinal, 'yyyy-MM-dd'));
        this.loading = false;
        console.log(error); // Imprimimos en consola el error
      });
      
    }

    handleCancel(): void {
      this.isVisibleConfirmar = false;
    }
}
