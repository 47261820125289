import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgZorroAntdModule, NZ_I18N, es_ES, NzModalModule, NzPaginationModule, NzInputModule } from 'ng-zorro-antd';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { HomeComponent } from './home/home.component';
import { ListadoTransaccionesComponent } from './transacciones/listado-transacciones/listado-transacciones.component';
import { ClientesComponent } from './clientes/clientes.component';
import { RepuestosComponent } from './repuestos/repuestos.component';
import { LoginComponent } from './login/login.component';
import { ProgramadasComponent } from './citas/programadas/programadas.component';
import { PasadasComponent } from './citas/pasadas/pasadas.component';
import { ContactoComponent } from './contacto/contacto.component';
import { AddRepuestosComponent } from './abm-repuestos/add-repuestos/add-repuestos.component';
import { BitacoraComponent } from './bitacora/bitacora.component';
import { PagoServiciosComponent } from './pago-servicios/pago-servicios.component';
import { ListadoServiciosComponent } from './transacciones/listado-servicios/listado-servicios.component';
import { ListadoTransaccionesMostradorComponent } from './transacciones/listado-transacciones-mostrador/listado-transacciones-mostrador.component';
import { SurveyRepuestosComponent } from './survey/survey-repuestos/survey-repuestos.component';
import { SurveyServiciosComponent } from './survey/survey-servicios/survey-servicios.component';
import { EncuestasWebComponent } from './reportes/encuestas-web/encuestas-web.component';
import { EncuestasMostradorComponent } from './reportes/encuestas-mostrador/encuestas-mostrador.component';
import { AddMarcaVehiculoComponent } from './abm-compatibilidad/add-marca-vehiculo/add-marca-vehiculo.component';
import { AddModeloVehiculoComponent } from './abm-compatibilidad/add-modelo-vehiculo/add-modelo-vehiculo.component';
import { AddMotorVehiculoComponent } from './abm-compatibilidad/add-motor-vehiculo/add-motor-vehiculo.component';
import { AddEquivalenciaComponent } from './add-equivalencia/add-equivalencia.component';
import { AgregarCompatibilidadComponent } from './abm-compatibilidad/agregar-compatibilidad/agregar-compatibilidad.component';
import { AgendarComponent } from './citas/agendar/agendar.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { BolsaTrabajoOfertaComponent } from './bolsa-trabajo-oferta/bolsa-trabajo-oferta.component';
import { BolsaTrabajoPostulacionesComponent } from './bolsa-trabajo-postulaciones/bolsa-trabajo-postulaciones.component';
import { BolsaTrabajoVerOfertasComponent } from './bolsa-trabajo-ver-ofertas/bolsa-trabajo-ver-ofertas.component';
import { ListadoComponent } from './usuarios/listado/listado.component';
import { ActualizacionComponent } from './usuarios/actualizacion/actualizacion.component';
import { AgmCoreModule } from '@agm/core';
import { B2bListadoClientesComponent } from './b2b/b2b-listado-clientes/b2b-listado-clientes.component';
import { B2bRegistroClientesComponent } from './b2b/b2b-registro-clientes/b2b-registro-clientes.component';
import { B2bListadoTransaccionesComponent } from './b2b/b2b-listado-transacciones/b2b-listado-transacciones.component';
registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ClientesComponent,
    ListadoTransaccionesComponent,
    RepuestosComponent,
    LoginComponent,
    ProgramadasComponent,
    PasadasComponent,
    ContactoComponent,
    AddRepuestosComponent,
    BitacoraComponent,
    PagoServiciosComponent,
    ListadoServiciosComponent,
    ListadoTransaccionesMostradorComponent,
    SurveyRepuestosComponent,
    SurveyServiciosComponent,
    EncuestasWebComponent,
    EncuestasMostradorComponent,
    AddMarcaVehiculoComponent,
    AddModeloVehiculoComponent,
    AddMotorVehiculoComponent,
    AddEquivalenciaComponent,
    AgregarCompatibilidadComponent,
    AgendarComponent,
    BolsaTrabajoOfertaComponent,
    BolsaTrabajoPostulacionesComponent,
    BolsaTrabajoVerOfertasComponent,
    ListadoComponent,
    ActualizacionComponent,
    B2bListadoClientesComponent,
    B2bRegistroClientesComponent,
    B2bListadoTransaccionesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgZorroAntdModule,
    NzFormModule,
    FormsModule,
    ReactiveFormsModule,
    NzTableModule,
    NzUploadModule,
    NzMessageModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzDatePickerModule,
    NzSpinModule,
    NzModalModule,
    NzInputModule,
    NzPaginationModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD8GCvYWVRiv0mRNOmbKFzz7kg8RPFYHag',
      libraries: ['places']
    })
  ],
  providers: [{ provide: NZ_I18N, useValue: es_ES }],
  bootstrap: [AppComponent]
})
export class AppModule { }